import React from "react";
import axios from "axios";
import Boundingbox from "react-bounding-box";
import { Button, Input } from "reactstrap";
import { baseURL } from "../../../baseURL";
import AtomicImage from "./OneImage";
export default function SearchByFace() {
  const [link, setLink] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [photos, setPhotos] = React.useState([]);
  const [type, setType] = React.useState(null);
  const [imageType, setImageType] = React.useState(null);
  const onChangeValue = (event) => {
    setType(event.target.value);
  };
  const onImageTypeChange = (event) => {
    setImageType(event.target.value);
  };
  const [data, setData] = React.useState({
    detection: {
      SearchedFaceBoundingBox: {
        Width: 0.1873374581336975,
        Height: 0.43148478865623474,
        Left: 0.40338146686553955,
        Top: 0.11897636950016022,
      },
      SearchedFaceConfidence: 99.99755859375,
      FaceMatches: [
        {
          Similarity: 99.99983978271484,
          Face: {
            FaceId: "5f515c3b-9860-4c17-9378-8b3a953b5923",
            BoundingBox: {
              Width: 0.30204400420188904,
              Height: 0.5182219743728638,
              Left: 0.1274069994688034,
              Top: 0.18477000296115875,
            },
            ImageId: "46676b78-d8df-3299-b940-22efcb08ef44",
            ExternalImageId: "67d914f1-6791-5196-be23-61d676572643.jpeg",
            Confidence: 99.9999008178711,
          },
          image: {},
        },
      ],
      FaceModelVersion: "5.0",
    },
    photos: [
      "https://pentashutter.s3.us-east-2.amazonaws.com/67d914f1-6791-5196-be23-61d676572643.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASSGF3ONNP75BJEH6%2F20210616%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210616T152003Z&X-Amz-Expires=900&X-Amz-Signature=ca9056b81c560d95b2a130045db56c3714d1687e8943d25e53726b6166aafad4&X-Amz-SignedHeaders=host",
    ],
    facesFromDb: [
      {
        id: 8,
        imageId: "46676b78-d8df-3299-b940-22efcb08ef44",
        photoName: "67d914f1-6791-5196-be23-61d676572643.jpeg",
        faceId: "5f515c3b-9860-4c17-9378-8b3a953b5923",
        responseData:
          '{"Face":{"FaceId":"5f515c3b-9860-4c17-9378-8b3a953b5923","BoundingBox":{"Width":0.30204421281814575,"Height":0.5182219743728638,"Left":0.12740743160247803,"Top":0.1847698986530304},"ImageId":"46676b78-d8df-3299-b940-22efcb08ef44","ExternalImageId":"67d914f1-6791-5196-be23-61d676572643.jpeg","Confidence":99.9998779296875},"FaceDetail":{"BoundingBox":{"Width":0.30204421281814575,"Height":0.5182219743728638,"Left":0.12740743160247803,"Top":0.1847698986530304},"Landmarks":[{"Type":"eyeLeft","X":0.2133977711200714,"Y":0.30115246772766113},{"Type":"eyeRight","X":0.34654033184051514,"Y":0.3218475878238678},{"Type":"mouthLeft","X":0.21075807511806488,"Y":0.5118914246559143},{"Type":"mouthRight","X":0.32217806577682495,"Y":0.5287841558456421},{"Type":"nose","X":0.29004523158073425,"Y":0.4040333330631256}],"Pose":{"Roll":6.259735107421875,"Yaw":5.8793416023254395,"Pitch":12.908364295959473},"Quality":{"Brightness":80.7907485961914,"Sharpness":86.86019134521484},"Confidence":99.9998779296875}}',
        createdAt: "2021-06-16T14:07:19.000Z",
        updatedAt: "2021-06-16T14:07:19.000Z",
      },
    ],
  });

  const getPhotos = async () => {
    try {
      console.log(file);
      console.log(type);
      if (link.length == 0 && !file) {
        return;
      }

      if (type == "Search") {
        if (imageType === "Link") {
          const response = await axios.get(`${baseURL}image`, {
            params: {
              imageURL: link,
            },
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.data) {
            setPhotos(response.data.data.photos);
            setData(response.data.data);
          }
        }
        if (imageType == "Image") {
          if (!file) return;
          const formData = new FormData();
          formData.append("file", file);
          const response = await axios.post(
            `${baseURL}image/face/face-by-upload`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.data.data) {
            setPhotos(response.data.data.photos);
            setData(response.data.data);
          }
        }
      } else {
        if (imageType == "Image") {
          if (!file) return;
          const formData = new FormData();
          formData.append("file", file);
          const response = await axios.post(
            `${baseURL}image/face-by-upload`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.data.data) {
            setData(response.data.data);
          }
        } else {
          const response = await axios.get(`${baseURL}image`, {
            params: {
              imageURL: link,
            },
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.data) {
            setData(response.data.data);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const RenderPhotos = () => {
    console.log(data?.detection?.FaceMatches);
    return (
      <>
        {data?.detection?.FaceMatches?.length > 0 &&
          data?.detection?.FaceMatches.map((photo, index) => {
            return (
              <>
                <AtomicImage
                  detection={photo}
                  index={index}
                  photo={photo.image}
                />
              </>
            );
          })}{" "}
      </>
    );
  };

  const RenderOnePhotoWithGender = () => {
    return (
      <>
        {data?.detection?.FaceDetails?.length > 0 &&
          data?.detection?.FaceDetails.map((photo, index) => {
            return (
              <>
                <AtomicImage
                  detection={{ Face: photo }}
                  index={index}
                  photo={link}
                />

                <div>
                  <span>Gender : {photo.Gender.Value}</span>
                </div>
                <div>
                  <span>Beard : {photo.Beard.Value ? "Yes" : "No"}</span>
                </div>
                <div>
                  <span>Eyes Open : {photo.EyesOpen.Value ? "Yes" : "No"}</span>
                </div>
                <div>
                  <span>
                    Eyeglasses : {photo.Eyeglasses.Value ? "Yes" : "No"}
                  </span>
                </div>
                <div>
                  <span>
                    AgeRange :{" "}
                    {photo.AgeRange.Low + " - " + photo.AgeRange.High}
                  </span>
                </div>

                <div>
                  <span>
                    Emotion :{" "}
                    {
                      photo?.Emotions.find((emoji) => emoji.Confidence > 50)
                        ?.Type
                    }
                  </span>
                </div>
              </>
            );
          })}
      </>
    );
  };

  return (
    <div className="containerX">
      <div onChange={onImageTypeChange}>
        <input
          style={{ margin: "20PX" }}
          type="radio"
          value="Image"
          name="imageType"
        />{" "}
        Upload
        <input
          style={{ margin: "20PX" }}
          type="radio"
          value="Link"
          name="imageType"
        />{" "}
        Link
      </div>
      <div>
        {imageType == "Link" && (
          <Input
            className="my-5"
            placeholder="Type image link"
            onChange={(e) => setLink(e.target.value)}
          />
        )}

        {imageType == "Image" && (
          <Input
            className="my-5"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        )}

        {imageType && (
          <div onChange={onChangeValue}>
            <input
              style={{ margin: "20PX" }}
              type="radio"
              value="Search"
              name="type"
            />{" "}
            Search for face
            <input
              style={{ margin: "20PX" }}
              type="radio"
              value="Detect"
              name="type"
            />{" "}
            Detect Gender and Emotions
          </div>
        )}

        <Button disabled={link.length == 0 && !file} onClick={getPhotos}>
          {" "}
          Search{" "}
        </Button>

        {type == "Search" && data?.detection?.FaceMatches && <RenderPhotos />}

        {type == "Detect" && data?.detection?.FaceDetails && (
          <RenderOnePhotoWithGender />
        )}
      </div>
    </div>
  );
}
