import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../../../baseURL";
import { Input, Label } from "reactstrap";
import ProductComponent from "./ProductComponent";
export default function AllProducts() {
  const [products, setProducts] = React.useState([]);

  const [categories, setCategories] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const getCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(baseURL + "custom-label/category");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async (categoryId) => {
    try {
      const response = await axios.get(baseURL + "custom-label/products", {
        params: {
          categoryId,
        },
      });
      const { data } = response.data;
      setProducts(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  React.useEffect(() => {
    getCategories();
    getProducts();
  }, []);

  React.useEffect(() => {
    getProducts(selectedCategory);
  }, [selectedCategory]);

  return (
    <div className="containerX">
      <div>
        <h2 style={{ color: "white" }}>All Products</h2>
        <Link to="/product">
          <h4 style={{ color: "white" }}>Back</h4>
        </Link>

        <Input
          type="select"
          className=" my-5"
          onChange={(e) => {
            const {
              target: { value },
            } = e;

            if (value == 0) {
              return setSelectedCategory(null);
            }

            return setSelectedCategory(value);
          }}
        >
          <option value={0}>All Categories</option>
          {categories?.map((cat, i) => (
            <option key={i} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </Input>
      </div>
      <div className="row mx-5">
        {products &&
          products.map((product, i) => (
            <ProductComponent
              product={product}
              key={i}
              categories={categories}
              setProducts={setProducts}
              products={products}
            />
          ))}
      </div>
    </div>
  );
}
