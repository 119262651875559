import axios from "axios";
import { Button } from "reactstrap";
import React from "react";
import { baseURL } from "../../../baseURL";
import CreateCategoryModal from "../CreateCategory";
import { Link } from "react-router-dom";

export default function ListCategory() {
  const [categories, setCategories] = React.useState([]);

  const [modal, setModal] = React.useState(false);

  const getCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(baseURL + "custom-label/category");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteCategory = async (categoryId) => {
    try {
      await axios.delete(baseURL + "custom-label/category", {
        params: { id: categoryId },
      });
      setCategories(categories.filter((cat) => cat.id != categoryId));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="containerX">
      <CreateCategoryModal
        modal={modal}
        setModal={setModal}
        cats={categories}
        setCats={setCategories}
      />
      <h2>Available Categories</h2>
      <div>
        <Button onClick={() => setModal(!modal)}>Create Category</Button>
      </div>
      <div>
        <Link to="/product">Back</Link>
      </div>

      <div>
        <div className="row my-4 justify-content-center mx-4">
          {categories?.map((cat, i) => {
            return (
              <div
                className="col-md mx-2 product-component px-5 my-4"
                key={i}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteCategory(cat.id)}
                >
                  X
                </div>
                <span style={{ wordBreak: "break-word" }}>{cat?.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
