import axios from "axios";
import React from "react";
import { Input, Button } from "reactstrap";
import { baseURL } from "../../baseURL";
import { Link } from "react-router-dom";

let filters = [
  {
    name: "Sepia",
    value: "sepia",
  },
  { name: "Old Photo", value: "old_photo" },
  { name: "Oil Paint New", value: "oil_paint_new" },
  { name: "Pencil Painting", value: "pencil_painting" },
  { name: "Simple Paint", value: "simple_paint" },
  { name: "Simple Paint2", value: "simple_paint2" },
  { name: "Simple Paint3", value: "simple_paint3" },
  { name: "impress", value: "impress" },
  { name: "van gog", value: "van_gog" },
  { name: "pointilism", value: "pointilism" },
  { name: "pointilism2", value: "pointilism2" },
  { name: "dry brush", value: "dry_brush" },
  { name: "chalk charcoal", value: "chalk_charcoal" },
  { name: "Sunny Colors", value: "sunny_colors" },

  { name: "Animated Eyes Burning", value: "animated_eyes", template_name: "0" },
  { name: "Animated Eyes Star", value: "animated_eyes1", template_name: "1" },
  { name: "Animated Eyes Dollar", value: "animated_eyes2", template_name: "2" },
  {
    name: "Animated Eyes Beating Hearts",
    value: "animated_eyes3",
    template_name: "3",
  },
  {
    name: "Animated Eyes Monster",
    value: "animated_eyes4",
    template_name: "4",
  },
  {
    name: "Animated Eyes Rolling",
    value: "animated_eyes5",
    template_name: "5",
  },

  { name: "Animated Head", value: "animated_head" },
  { name: "Animated Sniper ", value: "animated_sniper_aim" },
  { name: "Vintage", value: "vintage" },
  { name: "Ink Draw", value: "ink" },
  { name: "Dry Brush", value: "dry_brush" },
  { name: "Ice ", value: "ice" },
  { name: "Matrix ", value: "matrix" },
  { name: "Mosaic ", value: "mosaic" },
  { name: "mosaic circular ", value: "mosaic_circular" },
  { name: "pixelation", value: "pixelation" },
  { name: "fire", value: "fire" },
  { name: "deformation", value: "deformation" },
  { name: "plastic", value: "plastic" },
  { name: "isolines", value: "isolines" },
  { name: "edge detection", value: "edge_detection" },
  { name: "particles", value: "particles" },
  { name: "cartoonz draw", value: "cartoon" },
  { name: "Neon ", value: "neon" },
  { name: "Mona Lisa ", value: "collage", template_name: "mona_lisa" },
  { name: "Hearts ", value: "collage2", template_name: "1252" },
  { name: "Red cheecks", value: "collage3", template_name: "1174" },
  { name: "boxer", value: "collage4", template_name: "boxer" },

  {
    name: "Heart Eyes",
    value: "animated_effect1",
    template_name: "heart_eyes",
  },
  {
    name: "Burning Eyes",
    value: "animated_effect3",
    template_name: "burning_eyes",
  },
  {
    name: "Curves",
    value: "animated_effect2",
    template_name: "curves",
  },

  { name: "Winking", value: "caricature6", template_name: "6" },
  { name: "Fat Cheeck", value: "caricature14", template_name: "14" },
  { name: "Flirt", value: "caricature7", template_name: "7" },
  { name: "Offended", value: "caricature8", template_name: "8" },
  { name: "Martian", value: "caricature10", template_name: "10" },
  { name: "Tough Guy", value: "caricature12", template_name: "12" },
  { name: "Animated Blinking", value: "animated_blinking" },

  { name: "Animated Painting", value: "animated_painting" },
  { name: "Animated kaleidoscope", value: "animated_kaleidoscope" },
  { name: "Animated Magnifier", value: "animated_magnifier" },
  { name: "Animated Water Reflection", value: "animated_water_reflection" },
  { name: "Animated Puzzle", value: "animated_puzzle" },
  { name: "Animated Camera", value: "animated_camera" },
  { name: "Animated Radar", value: "animated_radar" },
  { name: "Animated Lights", value: "animated_lights" },
  { name: "Animated Plastic", value: "animated_plastic" },
  { name: "Animated Negative", value: "animated_negative" },
  { name: "Animated Hill of Ashes", value: "animated_hill_of_ashes" },
  { name: "Animated Sparkles", value: "animated_sparkles" },
  { name: "Animated Waterflow", value: "animated_waterflow" },

  {
    name: "Animated Winking",
    value: "animated_caricature6",
    template_name: "6",
  },

  {
    name: "Animated Smiling",
    value: "animated_caricature1",
    template_name: "1",
  },

  {
    name: "Animated Sad",
    value: "animated_caricature2",
    template_name: "2",
  },

  {
    name: "Animated Surprised",
    value: "animated_caricature3",
    template_name: "3",
  },

  {
    name: "Animated squint eyed",
    value: "animated_caricature4",
    template_name: "4",
  },

  {
    name: "Animated troll",
    value: "animated_caricature5",
    template_name: "5",
  },

  {
    name: "Animated Flirt",
    value: "animated_caricature7",
    template_name: "7",
  },

  {
    name: "Animated offended",
    value: "animated_caricature8",
    template_name: "8",
  },

  {
    name: "Animated Alien ",
    value: "animated_caricature9",
    template_name: "9",
  },

  {
    name: "Animated Martian",
    value: "animated_caricature10",
    template_name: "10",
  },
  {
    name: "Animated Bulb Head",
    value: "animated_caricature11",
    template_name: "11",
  },

  {
    name: "Animated Tough Guy",
    value: "animated_caricature12",
    template_name: "12",
  },

  {
    name: "Animated grotesque",
    value: "animated_caricature13",
    template_name: "13",
  },

  {
    name: "Animated Fat cheecked",
    value: "animated_caricature14",
    template_name: "14",
  },
];

export default function FilterPhoto() {
  const [file, setFile] = React.useState(null);

  const [image, setImage] = React.useState("");
  const [link, setLink] = React.useState("");

  const [filterName, setFilterName] = React.useState(filters[0].value);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [response, setResponse] = React.useState(null);

  const getFilteredPhoto = async () => {
    if (!file || !filterName ) {
      setError("File and filter Needed");
    }

    try {
      setLoading(true);
      setResponse(null);
      let name = filterName;

      if (name.includes("animated_caricature")) {
        name = "animated_caricature";
      }

      if (
        name.includes("caricature") &&
        !name.includes("animated_caricature")
      ) {
        name = "caricature";
      }

      if (name.includes("animated_effect")) {
        name = "animated_effect";
      }

      if (name.includes("collage")) {
        name = "collage";
      }

      if (name.includes("animated_eyes")) {
        name = "animated_eyes";
      }

      let formData = new FormData();

      formData.append("file", file);
      formData.append("link", link);
      formData.append("filterName", name);

      let hasTemp = filters.find((f) => f.value == filterName);

      if (hasTemp?.template_name) {
        formData.append("template_name", hasTemp.template_name);
      }

      const {
        data: {
          data: { phAPI },
        },
      } = await axios.post(`${baseURL}image/filter`, formData);

      if (phAPI) {
        setLoading(false);
        setImage(phAPI);
        setResponse(phAPI);
      }
    } catch (error) {
      setLoading(false);
      setLoading(JSON.stringify(error));
      setError(true);
    }
  };

  React.useEffect(() => {
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  }, [file]);

  return (
    <div className="containerX">
      <div>
        {image && !loading && <img src={image||link} width="500px" height="500px" />}
      </div>
      <div className="row  my-5">
        <div className=" col">
          <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
        </div>
      </div>

      {loading && <span>Loading......</span>}

      {response && (
        <div>
          <a href={response} target="_blank">
            Photo URL
          </a>
        </div>
      )}
      <div className="row my-2" style={{ width: "50vh" }}>
        <div className=" col">
          <Input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          ></Input>
        </div>
      </div>
      <div className="row my-2" style={{ width: "50vh" }}>
        <div className=" col">
          <Input
            type="select"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          >
            {filters &&
              filters.map((f, i) => {
                return (
                  <option
                    key={i}
                    value={f.value}
                    className="text text-capitalize"
                  >
                    {f.name}
                  </option>
                );
              })}
          </Input>
        </div>
      </div>
      <div>
        <Button onClick={getFilteredPhoto}>Upload</Button>
      </div>
    </div>
  );
}
