import React from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import FaceLogo from "./asset/face-recognition.png";
import AddFaces from "./asset/resume.png";
import ProductSearch from "./asset/studio.png";
import FilterIcon from './asset/filter.png'
export default function Main() {
  return (
    <div className="containerX">
      <Row>
        <Link
          style={{
            margin: "10px",
            color: "#282c34",
            textDecoration: "none",
          }}
          className="col"
          to="/product"
        >
          <span>
            <img className="face-rec-link" src={ProductSearch} height="200px" />
          </span>
          <div>
            <span style={{ color: "white" }}>Product search</span>
          </div>
        </Link>
        <Link
          style={{
            margin: "10px",
            textDecoration: "none",
          }}
          className="col"
          to="/upload"
        >
          <span>
            <img
              className="face-rec-link"
              src={AddFaces}
              alt="Add New Faces"
              height="200px"
            />
          </span>
          <div
            style={{
              marignRight: "20px",
            }}
          >
            <span style={{ color: "white" }}>Add New Faces</span>
          </div>
        </Link>
        <Link
          style={{
            margin: "10px",
            color: "#282c34",
            textDecoration: "none",
          }}
          className="col"
          to="/search"
        >
          <span>
            <img className="face-rec-link" src={FaceLogo} height="200px" />
          </span>
          <div>
            <span style={{ color: "white" }}>Search for faces</span>
          </div>
        </Link>
        <Link
          style={{
            margin: "10px",
            color: "#282c34",
            textDecoration: "none",
          }}
          className="col"
          to="/filter"
        >
          <span>
            <img className="face-rec-link" src={FilterIcon} height="200px" />
          </span>
          <div>
            <span style={{ color: "white" }}>Filter Photos</span>
          </div>
        </Link>
      </Row>
    </div>
  );
}
