import React from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import AddProduct from "../../asset/box.png";
import ProductSearch from "../../asset/studio.png";
import Products from "../../asset/products.png";
import Cats from "../../asset/categories.png";
export default function Product() {
  return (
    <div className="containerX">
      <Row>
        <Link
          style={{
            margin: "10px",
            color: "#282c34",
            textDecoration: "none",
          }}
          className="col"
          to="/product/category"
        >
          <span>
            <img className="face-rec-link" src={Cats} height="200px" />
          </span>
          <div>
            <span style={{ color: "white" }}>Categories</span>
          </div>
        </Link>
        <Link
          style={{
            margin: "10px",
            color: "#282c34",
            textDecoration: "none",
          }}
          className="col"
          to="/product/all"
        >
          <span>
            <img className="face-rec-link" src={Products} height="200px" />
          </span>
          <div>
            <span style={{ color: "white" }}>All Products</span>
          </div>
        </Link>
        <Link
          style={{
            margin: "10px",
            color: "#282c34",
            textDecoration: "none",
          }}
          className="col"
          to="/product/search"
        >
          <span>
            <img className="face-rec-link" src={ProductSearch} height="200px" />
          </span>
          <div>
            <span style={{ color: "white" }}>Search</span>
          </div>
        </Link>
        <Link
          style={{
            margin: "10px",
            textDecoration: "none",
          }}
          className="col"
          to="/product/upload"
        >
          <span>
            <img
              className="face-rec-link"
              src={AddProduct}
              alt="Add New Faces"
              height="200px"
            />
          </span>
          <div
            style={{
              marignRight: "20px",
            }}
          >
            <span style={{ color: "white" }}>Add New Products</span>
          </div>
        </Link>
      </Row>
    </div>
  );
}
