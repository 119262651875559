import React from "react";
import UpdateProduct from "./UpdateModal";
import { Button, ModalBody, ModalFooter, Modal } from "reactstrap";
import axios from "axios";
import { baseURL } from "../../../../baseURL";

export default function ProductComponent({
  product,
  categories,
  products,
  setProducts,
}) {
  const [modal, setModal] = React.useState(false);

  const [deleteModal, setDeleteModal] = React.useState(false);

  const deleteProduct = async () => {
    try {
      const response = await axios.delete(baseURL + "custom-label/products", {
        params: {
          id: product.id,
        },
      });
      const { data } = response.data;
      setProducts(products.filter((p) => p.id != product.id));
      toggleDelete()
    } catch (error) {
      console.log(error.response);
    }
  };
  const toggleDelete = () => setDeleteModal(!deleteModal);
  return (
    <div className="col mx-5 product-component my-4">
      <Modal isOpen={deleteModal} toggle={toggleDelete}>
        <ModalBody>
          <h4>Are you sure that you want to delete {product.displayName}</h4>
        </ModalBody>
        <ModalFooter>
          <Button onClick={deleteProduct}>Yes</Button>
          <Button onClick={() => setDeleteModal(false)}>No</Button>
        </ModalFooter>
      </Modal>
      <UpdateProduct
        modal={modal}
        setModal={setModal}
        categories={categories}
        productName={product.displayName}
        productCategory={product?.cat[0] ? product?.cat[0].id : 0}
        productId={product.id}
      />
      <div className="my-2 text text-capitalize">
        <span>{product?.displayName}</span>
      </div>
      <div className="my-2 text text-capitalize">
        <span style={{ fontSize: "15px" }}>
          Category:{" "}
          {product?.cat[0] ? (
            <span style={{ color: "green" }}>{product?.cat[0].name}</span>
          ) : (
            <span style={{ color: "red" }}>Not in category</span>
          )}
        </span>
      </div>
      <div>
        {product?.medias?.map((media) => {
          return (
            <img
              src={media.url}
              height="200px"
              width="200px"
              className="face-rec-link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                return window.open(media.url, "_blank");
              }}
            />
          );
        })}
      </div>
      <div className="my-4">
        <Button
          color="warning"
          className="mx-2"
          onClick={() => {
            setModal(true);
          }}
        >
          Update
        </Button>
        <Button
          color="danger"
          className="mx-2"
          onClick={() => {
            setDeleteModal(true);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
