import axios from "axios";
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { baseURL } from "../../../baseURL";
export default function CreateCategoryModal({
  modal,
  setModal,
  cats,
  setCats,
}) {
  const [name, setName] = React.useState("");
  const toggle = () => setModal(!modal);

  const createCategory = async () => {
    try {
      const {
        data: { data },
      } = await axios.post(baseURL + "custom-label/category", { name });
      setCats([...cats, data]);
      setName("");
      toggle();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create Category</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={createCategory}
            disabled={name.length == 0}
          >
            Create
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
