import axios from "axios";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Input } from "reactstrap";
import { baseURL } from "../../../baseURL";
function MyDropzone() {
  const [files, setFiles] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [productName, setProductName] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [productType, setProductType] = React.useState("single");
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const getCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(baseURL + "custom-label/category");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
    console.log(files);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const indexFileFaces = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      await files.map((file) => formData.append("file", file));
      formData.append("name", productName);
      formData.append("categoryId", selectedCategory);
      formData.append("type", productType);

      const response = await axios.post(`${baseURL}custom-label`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        setSuccess(true);
        setFiles([]);
        setProductName(null);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="containerX">
      <div>
        {files &&
          files.map((file) => (
            <img
              style={{
                height: "50px",
              }}
              src={URL.createObjectURL(file)}
            />
          ))}
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' drops some files here, or click to select files</p>
      </div>
      <div className="my-5">
        <Input
          type="select"
          value={productType}
          className=" my-2"
          onChange={(e) => {
            const {
              target: { value },
            } = e;

            return setProductType(value);
          }}
        >
          <option value={"multiple"}>Multiple Products</option>
          <option value={"single"}>Single Product</option>
        </Input>
        <Input
          type="select"
          className=" my-2"
          onChange={(e) => {
            const {
              target: { value },
            } = e;

            if (value == 0) {
              return setSelectedCategory(null);
            }

            return setSelectedCategory(value);
          }}
        >
          <option value={0}>All</option>
          {categories?.map((cat, i) => (
            <option key={i} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </Input>
        <Input
          placeholder="Product Name"
          onChange={(e) => setProductName(e.target?.value)}
        />
      </div>
      <Button
        disabled={files.length == 0 || !productName}
        onClick={indexFileFaces}
      >
        Upload
      </Button>
      {loading && <h4>Loading.... Please wait</h4>}
      {success && (
        <h4>
          Files Has been uploaded, it's now being proccessed and may take about
          30 minutes
        </h4>
      )}
    </div>
  );
}

export default MyDropzone;
