import { Component } from "react";
import Boundingbox from "react-bounding-box";

const RenderImage = ({ photo, height, width, detection, index }) => {
  const face = detection.Face;
  const bounBox = face.BoundingBox;
  const params = {
    image: photo,
    boxes: [
      [
        bounBox.Left * width,
        bounBox.Top * height,
        bounBox.Width * width,
        bounBox.Height * height,
      ],
    ],
    options: {
      colors: {
        normal: "#191619",
        selected: "#191619",
        unselected: "#191619",
      },
      style: {
        maxWidth: "100%",
        maxHeight: "500px",
      },
      //showLabels: false
    },
  };

  return (
    <Boundingbox
      image={params.image}
      boxes={params.boxes}
      options={params.options}
      //  segmentationJson={'./ADE_val_00000761.json'}
    />
  );
};
class AtomicImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {},
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }
  onImgLoad({ target: img }) {
    console.log("immmmg", img);
    this.setState({
      dimensions: {
        height: img.height,
        width: img.width,
      },
    });
  }
  render() {
    const { photo: src } = this.props;
    console.log("ssrc", src);
    const { width, height } = this.state.dimensions;

    return (
      <div>
        <img style={{ display: "none" }} onLoad={this.onImgLoad} src={src} />{" "}
        {this.state.dimensions.width && (
          <>
            <RenderImage
              detection={this.props.detection}
              width={this.state.dimensions.width}
              height={this.state.dimensions.height}
              index={this.props.index}
              photo={src}
            />
          </>
        )}{" "}
      </div>
    );
  }
}

export default AtomicImage;
