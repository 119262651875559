import axios from "axios";
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { baseURL } from "../../../../../baseURL";

export default function UpdateProduct({
  setModal,
  modal,
  productCategory,
  productName,
  categories,
  productId,
}) {
  const toggle = () => setModal(!modal);

  const [values, setValues] = React.useState({
    categoryId: productCategory,
    name: productName,
    productId: productId,
  });

  React.useEffect(() => {
    setValues({
      categoryId: productCategory,
      name: productName,
      productId,
    });
  }, []);

  const onChange = (e) => {
    console.log(values);
    const {
      target: { value, name },
    } = e;
    setValues({ ...values, [name]: value });
  };
  const updateProduct = async (e) => {
    e?.preventDefault();

    try {
      const {
        data: { data },
      } = await axios.post(baseURL + "custom-label/products", { ...values });

  
      window.location.reload();
      toggle();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update {productName}</ModalHeader>
        <ModalBody>
          <Form onSubmit={updateProduct}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                name="name"
                type="text"
                value={values.name}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup className=" my-5">
              <Label>Category</Label>
              <Input
                type="select"
                name="categoryId"
                onChange={onChange}
                value={values.categoryId}
              >
                <option value={0}>Not in category</option>
                {categories?.map((cat, i) => (
                  <option key={i} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <Button
            color="primary"
            type="submit"
            disabled={
              values.name == productName && values.categoryId == productCategory
            }
          >
            Update
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}
