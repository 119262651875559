import axios from "axios";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "reactstrap";
import { baseURL } from "../../../baseURL";
function MyDropzone() {
  const [files, setFiles] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        setFiles([...files, file]);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const indexFileFaces = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      await files.map((file) => formData.append("file", file));
      const response = await axios.post(`${baseURL}image/face`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("files", formData.get("file"));
      if (response.data.data) {
        setSuccess(true);
        setFiles([]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="containerX">
      <div>
        {files &&
          files.map((file, i) => (
            <img
              style={{
                height: "50px",
              }}
              key={i}
              alt=""
              src={URL.createObjectURL(file)}
            />
          ))}
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' drops some files here, or click to select files</p>
      </div>
      <Button disabled={files.length == 0} onClick={indexFileFaces}>
        Upload
      </Button>
      {loading && <h4>Loading.... Please wait</h4>}
      {success && <h4>Files Has been uploaded you can now test</h4>}
    </div>
  );
}

export default MyDropzone;
