import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "./Main";
import Upload from "./Pages/Face/Upload";
import SearchByFace from "./Pages/Face/SearchByFace/SearchByFace";
import ProductSearch from "./Pages/Product/ProductSearch";
import Product from "./Pages/Product";
import UploadProduct from "./Pages/Product/UploadProduct";
import AllProducts from "./Pages/Product/AllProducts";
import ListCategory from "./Pages/Category/ListCategory";
import FilterPhoto from "./Pages/FilterPhotos";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route component={Main} exact path="/" />
          <Route exact path="/upload" component={Upload} />
          <Route exact path="/search" component={SearchByFace} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/product/upload" component={UploadProduct} />
          <Route exact path="/product/search" component={ProductSearch} />
          <Route exact path="/product/all" component={AllProducts} />
          <Route exact path="/product/category" component={ListCategory} />
          <Route exact path="/filter" component={FilterPhoto} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
