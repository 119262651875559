import axios from "axios";
import React from "react";
import { Input, Button, Row, Col } from "reactstrap";
import ProductSearchImage from "../../../asset/studio.png";
import { baseURL } from "../../../baseURL";

export default function ProductSearch() {
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [response, setResponse] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [score, setScore] = React.useState(null);

  const getCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(baseURL + "custom-label/category");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const searchFromAWS = async () => {
    try {
      setResponse(null);
      setLoading(true);
      setError(null)
      const formData = new FormData();
      formData.append("file", file);
      if (selectedCategory) {
        formData.append("categoryId", selectedCategory);
      }

      if (score) {
        formData.append("score", score / 100);
      }
      const {
        data: { data },
      } = await axios.post(`${baseURL}custom-label/search-image`, formData);
      setLoading(false);
      let productsData = data.sort(
        ({ score: { score: a } }, { score: { score: b } }) => b - a
      );
      setResponse(productsData);
      if (productsData.length == 0) {
        setEmpty(true);
      }
      console.log("response", productsData);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      if (error?.response?.data?.errors) {
        let customError = error?.response?.data?.errors;
        if (customError.includes("ready")) {
          return setError("We need to start our model first");
        } else {
          setError(customError);
        }
      }
    }
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <div className="containerX">
        <img
          className="face-rec-link"
          src={ProductSearchImage}
          height="200px"
        />
        <Input
          type="file"
          className="my-4"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <div style={{ width: "400px" }}>
          <Input
            type="select"
            className=" my-2"
            onChange={(e) => {
              const {
                target: { value },
              } = e;

              if (value == 0) {
                return setSelectedCategory(null);
              }

              return setSelectedCategory(value);
            }}
          >
            <option value={0}>All</option>
            {categories?.map((cat, i) => (
              <option key={i} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </Input>
        </div>
        <div style={{ width: "400px" }}>
          <Input
            type="select"
            className=" my-2"
            onChange={(e) => {
              const value = e.target.value;
              if (value == 0) {
                return setScore(null);
              }
              setScore(parseFloat(value));
            }}
          >
            <option value={0}>All Scores</option>
            <option value={65}>More than 65 </option>
            <option value={40}>More than 40</option>
            <option value={20}>More than 20</option>
          </Input>
        </div>
        <div className="my-4">
          {file && (
            <div className="my-4">
              <h4>Uploaded Image</h4>
              <img src={URL.createObjectURL(file)} height="250px" />
            </div>
          )}

          <h4>{empty ? "No Porducts have been found" : "Similiar Products"}</h4>
        </div>
        {loading && "Searching..."}
        <Button disabled={!file} className="my-5" onClick={searchFromAWS}>
          Search
        </Button>
        {response &&
          response.length != 0 &&
          response.map((product) => {
            const { displayName, score, medias } = product;
            return (
              <div
                className="mb-4"
                style={{ border: "3px solid white", width: "50%" }}
              >
                <Row>
                  <Col>Name: </Col>
                  <Col>{displayName}</Col>
                </Row>
                <Row>
                  <Col>Similraty: </Col>
                  <Col>{parseFloat(score.score).toFixed(2) * 100}%</Col>
                </Row>
                <Row>
                  {medias &&
                    medias.length != 0 &&
                    medias.map((media) => (
                      <Col>
                        <img height="150px" src={media.url} />
                      </Col>
                    ))}
                </Row>
              </div>
            );
          })}

        {error && <>Error: {error}</>}
      </div>
    </div>
  );
}
